@import '~@bryntum/schedulerpro/schedulerpro.classic-dark.css';
@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";
@import '~react-contexify/dist/ReactContexify.css';
@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

body {
    font-family: "Poppins", sans-serif;
    overflow: hidden;
}

.green {
    color: green;
}

.amber {
    color: orange;
}

.red {
    color: red;
}

.popper {
    border-radius: 1rem;
    overflow: hidden;
    border: 2px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.react-grid-placeholder {
    /*transition: 0ms !important;*/
    background-color: rgb(150, 150, 150) !important;
}

.react-resizable-handle {
    right: 8px !important;
}

.react-grid-item {
    pointer-events: auto;
}

.b-sch-event {
    border-radius: 0.125rem 0.125rem 0.125rem 0.125rem;
}

.mono {
    font-family: 'JetBrains Mono', monospace;
}

.text-primary {
    color: #106c12 !important;
}

.text-danger {
    color: #ff0000bf !important;
}

.center {
    display: grid;
    justify-content: center;
    align-content: center;
}

.center-vertically {
    display: grid;
    align-content: center;
}

.b-widget-scroller::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.b-widget-scroller::-webkit-scrollbar-thumb {
    background: #313a42;
    border-radius: 1px;
}

.b-widget-scroller::-webkit-scrollbar-track {
    background: #1e272e;
}

.b-widget-scroller::-webkit-scrollbar-thumb:hover {
    background: #0a151b;
}

.link-inner:hover {
    cursor: pointer;
    text-decoration: underline;
}

label.b-grid-subgrid {
    background: #fff;
    white-space: nowrap;
    padding: 4px;
    font-weight: 500;
}

.b-grid-subgrid .recevied-timerange {
    background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0) 46%, #00ffffbf 49%, #00ffffbf 51%, rgba(0, 0, 0, 0) 55%);
    background-size: 6px 6px !important;
    color: #00ffffbf;
    font-weight: 400;
    border-right: 2px solid #00ffffbf;
}

.b-grid-subgrid .deadline-timerange {
    background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0) 46%, #ff0000bf 49%, #ff0000bf 51%, rgba(0, 0, 0, 0) 55%);
    background-size: 6px 6px !important;
    color: red;
    font-weight: 400;
}

.b-grid-header .b-sch-timerange.b-sch-line {
    background-color: #fab005 !important;
}

.b-grid-header .b-sch-timerange {
    background-color: transparent !important;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: 12px;
    padding-right: 12px;
}

.b-sch-label-right-text {
    border-width: 1px;
    border-style: solid;
    border-radius: 999px;
    padding: 0 4px;
}

.b-sch-label-right-line {
    position: absolute;
    width: 20px;
    border-width: 1px;
    border-style: solid;
    right: 100%;
    top: 50%;
    transform: translateY(-75%);
}

.use-todos {
    position: relative;
}

.use-todos:hover .create-todo {
    display: block;
}

.nestable-list, .nestable-item {
    margin: 0 !important;
}

.react-contexify {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    background-color: #2b343b !important;
}

.react-contexify__item__content {
    color: white !important;
}

.react-contexify__item__content:hover {
    background-color: #1e272e !important;
}

.marker-icon {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    font-size: 2rem;
    color: white;
}

.react-grid-placeholder {
    border-radius: 0.25rem;
}

.react-resizable-handle {
    width: 32px !important;
    height: 32px !important;
    cursor: se-resize;
    background-image: none;
    right: -18px !important;
    bottom: -14px !important;
}

.react-resizable-handle::after {
    display: none !important;
}